@import "../common/variables";
@import "../lavenst.scss";

.bill_edit_view {
    margin-left: 128px;
    width: calc(100vw - 128px);

    @include mobile {
        margin-left: 0;
        width: 100vw;
    }

    @include tablet {
        margin-left: 0;
        width: 100vw;
    }

    & .product_list_container {
        display: flex;

        @include mobile {
            flex-direction: column-reverse;
        }

        @include tablet {
            flex-direction: column-reverse;
        }
    }
}