@import "../common/variables";
@import "../lavenst.scss";

.settings_view {
    margin-left: 128px;
    width: calc(100vw - 128px);

    @include mobile {
        width: 100vw;
        margin-left: 0;
    }

    @include tablet {
        width: 100vw;
        margin-left: 0;
    }

    & .settings_container {
        padding-top: 128px;
        display: flex;
        flex-wrap: wrap;

        @include mobile {
            margin-left: 0;
        }

        @include tablet {
            margin-left: 0;
        }

        & .setting_card {
            display: flex;
            align-items: center;
            max-width: 240px;
            padding: 16px 32px;
            box-shadow: 0 3px 6px rgba(0,0,0,.15);
            border-radius: 8px;
            background: #fff;
            text-decoration: none;
            margin: 8px 16px 8px 16px;
            position: relative;

            @include mobile {
                max-width: calc(100% - 32px);
                width: calc(100% - 32px);
            }

            @include tablet {
                max-width: calc(100% - 32px);
                width: calc(100% - 32px);
            }

            & .icon_container{
                width: 64px;
                height: 64px;
                border-radius: 50%;
                background-color: $-dark-2;
                display: flex;
                align-items: center;
                justify-content: center;

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 0;
                    height: 100%;
                    background-color: $-dark-2;
                    opacity: 0;
                    z-index: 0;
                    border-radius:8px;
                    transition: all .4s ease;
                    top: 0;
                    left: 0;
                }

                & .icon {
                    font-size: 24px;
                    color: #fff;
                    z-index: 1;
                }
            }

            & .setting_title{
                text-decoration: none;
                color: $-dark-3;
                margin-left: 24px;
                font-weight: 600;
                z-index: 2;
                transition: all .4s ease;
            }
        }
    }
}