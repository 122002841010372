//DEVICES
$mobile-width: 480px; 
$tablet-width: 480px; 
$desktop-width: 1600px;  
$desktopHD-width: 1280px;

@mixin mobile { @media (max-width: #{$mobile-width - 1px}) {     
	 @content;   
	} 
}  
  
  @mixin tablet { @media (min-width: #{$tablet-width}) and (max-width: #{$desktopHD-width - 1px}) {       
	 @content;   
   } 
}  
  
  @mixin desktop {   @media (min-width: #{$desktop-width}) {     
	@content;  
   } 
}

@mixin desktopHD {   @media (min-width: #{$desktopHD-width}) and (max-width: 1366px) {     
	@content;  
   } 
}

@mixin verticalCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


            
