/*COPYRIGHT (c) 2020 Lavensoft, Designed by Laven Studio in Ho Chi Minh City*/
@import "common/variables";
@import "common/motions";
@import "common/fonts";
@import "common/mixin";
@import "common/default";
@import "common/views";
@import "components/Views.scss";
@import "components/Textbox.scss";
@import "components/Buttons.scss";
@import "components/Selectbox.scss";
@import "components/Tab.scss";
@import "components/ChamCong.scss";
@import "components/MonthPicker.scss";

/*DEFINE*/
:root {
    --shadow-0: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    --shadow-1: 0 6px 14px 0 rgba(0, 0, 0, 0.22);

    /*COLOR*/
    --color-50: #ede7f6;
    --color-100: #d1c4e9;
    --color-200: #b39ddb;
    --color-300: #9575cd;
    --color-400: #7e57c2;
    --color-500: #673ab7;
    --color-600: #5e35b1;
    --color-700: #512da8;
    --color-800: #4527a0;
    --color-900: #311b92;
    --color-A100: #b388ff;
    --color-A200: #7c4dff;
    --color-A400: #651fff;
    --color-A700: #6200ea;
}

.product_container {
    width: calc(100vw - #{$-side-bar-width});
    height: 100%;
    margin-left: 96px + $-side-bar-width;
    padding-top: 115px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.version {
    position: fixed;
    bottom: 8px;
    right: 16px;

    & a {
        color: rgb(65, 65, 65);
        font-size: 12px;
        font-weight: 500;
        text-decoration: none;
    }

    @include mobile {
        display: none;
    }

    @include tablet {
        display: none;
    }
}

.section_scrollable {
    overflow: auto;
    height: 100vh;
    max-height: 100vh;

    & .section.confess {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 64px;
    
        @include mobile {
            margin: 0;
            padding: 0;
        }
    
        @include tablet {
            margin: 0;
            padding: 0;
        }
    }
}



/*SCROLL BAR*/

::-webkit-scrollbar {
    width: 12px;
    background-color: #fff;

    &:hover {
        background-color: #eaeaea;
    }
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    box-sizing: border-box;
    border: 4px solid #fff;
    border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
    border: 3px solid #eaeaea;
}

.cXcRCo {
    z-index: 99;
}

html,
body {
    margin: 0;
    height: 100%;
    font-family: $-default-font;
    overflow-x: hidden;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;

   /* & div#root {
        > div {
            display: flex;

            & main {
                flex: 1;
            }
        }
    }*/
}