@import "../../styles/common/variables";
@import "../lavenst.scss";

.table_view {
    width: auto;
    height: 100vh;
    background-color: #fff;
    position: relative;
    overflow: auto;

    & .table_header {
        position: fixed;
        width: calc(100vw - (96px));
        height: 64px;
        right: 0;
        margin-top: 112px;
        display: flex;
        align-items: center;
        z-index: $-menu-z-index;

        & button {
            min-width: 112px;
            height: 100%;
            cursor: pointer;
            color: $-dark-3;
            font-size: 13px;
            font-weight: 600;

            &:active{
                box-shadow: none;
            }
        }
    }

    &.bill_edit {
        & .table {
            margin-top: 40px;
            margin-left: 0px;
            width: 100%;
        }
    }

    & .table {
        margin-left: 128px;
        margin-top:128px;
        width: calc(100vw - (128px))!important;
        height: calc(100% - 128px)!important;
        right: 0!important;

        @include mobile {
            width: 100%!important;
            margin-top:64px!important;
            margin-left: 0!important;
            height: calc(100% - 64px)!important;
        }

        @include tablet {
            width: 100%!important;
            margin-left: 0!important;
            margin-top:64px!important;
            height: calc(100% - 64px)!important;
        }

        & .sc-AxhCb.lkTobJ.sticky-table, & .sc-AxhCb.dIGrPx.sticky-table {
            & .sc-AxjAm.iFUdnr.sticky-table-table, & .sc-AxjAm.bcMPWx.sticky-table-table{

                & .sc-AxirZ.gHqKkC.sticky-table-cell, & .sc-AxirZ.bJCmFu.sticky-table-cell {
                    background: #fff!important;
                    font-size: 15px!important;
                    color: $-dark!important;
                    font-weight: 500!important;
                    font-family: $-default-font!important;
                    height: 56px!important;
                    padding-top: 22px!important;
                    padding-bottom: 22px!important;
                    padding-left: 28px!important;
                    padding-right: 28px!important;

                    @include tablet {
                        padding-left: 10px!important;
                        padding-right: 10px!important;
                        padding-top: 6px!important;
                        padding-bottom: 6px!important;
                        font-size: 8px!important;
                        height: 22px!important;
                    }
                }

                & .sc-AxiKw.juGFnY.row.sticky-table-row, & .sc-AxiKw.eSbheu.row.sticky-table-row {

                    & .sc-AxirZ.gHqKkC.sticky-table-cell, & .sc-AxirZ.bJCmFu.sticky-table-cell {
                        //background: $-white-3;
                        height: 78px!important;
                        font-size: 15px!important;
                        text-align: center!important;
                        border: none!important;
                        transition: all .4s ease!important;
                        position: relative!important;

                        @include tablet {
                            font-size: 8px!important;
                            height: 22px!important;
                        }

                        & .row {
                            width: 100%!important;
                            height: 100%!important;
                            top: 0!important;
                            left: 0!important;
                            display: flex!important;
                            align-items: center!important;
                            justify-content: center!important;
                            padding: 0 8px!important;
                            border-radius: 8px!important;
                            height: 32px!important;

                            @include tablet {
                                height: 20px!important;
                                width: 80%!important;
                                font-size: 8px!important;

                                & a {
                                    font-size: 8px!important;
                                }
                            }

                            & a {
                                font-size: 12px!important;
                                color: #fff!important;
                                text-align: center!important;
                            }

                            //COLORS
                            &.blue {
                                background: #3880ff!important;
                            }
        
                            &.purple {
                                background: #33b127!important;
                            }

                            &.brown {
                                background: rgb(187, 60, 60)!important;
                            }
        
                            &.salmon {
                                background: salmon!important;
                            }

                            &.violet {
                                background: violet!important;
                            }
        
                            &.wheat {
                                background: tan!important;
                            }
        
                            &.orange {
                                background: orange!important;
                            }
        
                            &.yellow {
                                background: #ffc409!important;
                            }
        
                            &.red {
                                background: #eb445a!important;
                            }
        
                            &.gray {
                                background: #888!important;
                            }
        
                            &.black {
                                background: #414141!important;
                            }
                        }

                        & a {
                            text-decoration: none!important;
                            color: #414141!important;
                            display: block!important;
                            width: 100%!important;
                            text-align:left;
                            font-size: 14px!important;
                        }

                        &:first-child {
                            text-align: center!important;
                            border-right: 1px solid $-white-8!important;
                        }

                        &:last-child {
                        }
                    }
                }

                //HEADER
                & .sc-AxiKw.juGFnY.header.sticky-table-row, & .sc-AxiKw.eSbheu.header.sticky-table-row{
                    & .sc-AxirZ.gHqKkC.sticky-table-cell, & .sc-AxirZ.bJCmFu.sticky-table-cell {
                        border: none!important;
                        border-bottom: 1px solid $-white-8!important;
                        font-weight: 600!important;

                        &:first-child {
                            text-align: center!important;
                            border-right: 1px solid $-white-8!important;
                        }   
                    }
                }
            }
        }
    }
}