.modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.55);
    //backdrop-filter: blur(15px) saturate(280%);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;

    &.m_calendar {

        & h1 {
            margin:32px 32px 0 32px;
            font-size: 18px!important;
            user-select: all;  
            text-align: left;
            width: calc(100% - 64px);
            display: block; 
        }
        
        & span {
            margin: 4px 32px;
            font-size: 16px;
            font-weight: 500;
            color: #414141;
            user-select: all;
            text-align: left;
            width: calc(100% - 64px);
            display: block;
        }

        & a {
            color: #fff;
            text-decoration: none;
        }
    }

    & .close_btn {
        position: absolute;
        top: 24px;
        right: 24px;
        border-radius: 50%;
        background-color: rgba(255,255,255,.55);
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        & .icon {
            font-size: 14px;
        }

        &:active {
            box-shadow: none;
        }
    }

    & .form_container {
        width: 300px;
        background-color: #fff;
        border-radius: 16px;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0;
        max-height: 80%;
        overflow: auto;

        & h1 {
            font-size: 24px;
            margin-top: 32px;
            margin-bottom: 20px;
        }

        & .box {
            display: flex;
            justify-content: center;
            flex-direction: column;
            position: unset;
            width: 234px;
            max-width: 234px!important;

            & .laven.date_picker {
                width: 100%!important;
            }

            & select {
                width: 100%;
            }

            &.calendar {
                height: 70px;
                width: 100%;
                justify-content: unset;

                & .hPEdlB {
                    margin-top: 37px;
                    position: absolute;

                    & input {
                        max-width: 217px;
                    }

                    & .cXcRCo {
                        margin-top: 8px;
                    }
                }
            }

            & .title {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 4px;
                margin-top: 16px;
                font-size: 12.5px;
                font-weight: 600;
                color: #696969;
            }

            & .colors_picker_container {
                display: flex;
                width: 215px;

                & .color {
                    flex: 1;
                    background:#414141;
                    border-radius: 6px;
                    cursor: pointer;
                    margin: 4px;
                    box-sizing: border-box;
                    display: inline-block;

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    &.active {
                        border: 2px solid #fff;
                        margin: 2px;
                        box-shadow: 0 0 4px rgba(0,0,0,.45);
                    }

                    &:after {
                        content: "";
                        display: block;
                        padding-bottom: 100%;
                    }

                    //COLORS
                    &.blue {
                        background: #3880ff;
                    }

                    &.purple {
                        background: #33b127;
                    }

                    &.brown {
                        background: rgb(187, 60, 60);
                    }

                    &.salmon {
                        background: salmon;
                    }
                    &.violet {
                        background: violet;
                    }

                    &.wheat {
                        background: tan;
                    }

                    &.orange {
                        background: orange;
                    }

                    &.yellow {
                        background: #ffc409;
                    }

                    &.red {
                        background: #eb445a;
                    }

                    &.gray {
                        background: #888;
                    }

                    & .black {
                        background: #414141;
                    }
                }
            }
        }

        & .column {
            width: 215px;
            margin-top: 32px;
            margin-bottom: 32px;
            display: flex;
            align-items: center;
            justify-content: center;

            & button {
                flex: 1;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}