.analytics_view{
    height: 100vh;
    width: calc(100vw - 96px);
    margin-left: 128px;
    padding-top: 31px;

    @include mobile {
        width: 100%;
        margin-left: 0;
        padding-top: 96px;
    }

    @include tablet {
        width: 100%;
        margin-left: 0;
        padding-top: 96px;
    }

    & .toolbar {
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: wrap;

        @include mobile {
            width: 100%;
        }

        @include tablet {
            width: 100%;
        }

        &.filter {
            margin-top: 64px;
        }

        & .group {
            display: flex;
            position: relative;
            right: 0;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            & h1 {
                margin-right: 24px;
                margin-left: 24px;
            }

            &.filter {

                @include mobile {
                    flex-direction: column;
                    width: 100%;
                    position: relative;
                }

                @include tablet {
                    flex-direction: column;
                    width: 100%;
                    position: relative;
                }

                @include mobile {
                    & .box {
                        display: flex;
                        flex-direction: column;
    
                        & input, select {
                            width: 80vw;
                        }
    
                        & select {
                            margin-top: 24px;
                        }
                    }

                    & h1 {
                        margin-right: 0;
                        margin-left: 0;
                    }
                }

                @include tablet {
                    & .box {
                        display: flex;
                        flex-direction: column;
    
                        & input, select {
                            width: 80vw;
                        }
    
                        & select {
                            margin-top: 24px;
                        }
                    }

                    & h1 {
                        margin-right: 0;
                        margin-left: 0;
                    }
                }

                & select {
                    margin: 0 8px;
                }

                & button {
                    margin: 0 0 0 8px;
                    float:right;

                    @include tablet {
                        margin-top: 24px;
                    }

                    @include mobile {
                        margin-top: 24px;
                    }
                }
            }
        }
    }
}

.load_view {
    width: calc(100vw - 96px);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}