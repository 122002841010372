select {
    flex: 1;
    border: 1px solid #ced4da;
    padding: .375rem .75rem;
    transition: all .15s ease;
    max-width: 266px;
    border-radius: 4px;
    font-family: $-default-font;
    font-weight: 500;
    background-color: #fff!important;

    &:focus {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
    }
}