@import '../common/variables';
@import '../lavenst.scss';

.side_bar_container {
    width: calc(#{$-side-bar-width} + 32px);
    height: calc(100vh - 48px);
    margin: 24px;
    display: inline-block;
    position: fixed;
    z-index:999999!important;

    &.min {
        width: 32px;
        $-side-bar-width: 32px;

        @include mobile {
            display: none;
        }

        & .side_bar {
            width: 32px;
            $-side-bar-width: 32px;

            & hr {
                width: 100%;
                height: 1px;
                border: none;
                background: #e6e6e6;
            }

            & .item {
                display: flex;
                align-items: center;
                justify-content: center;

                &.avatar_container {

                    & button {
                        width: auto;
                        display: inline-block;
                        cursor: pointer;

                        & .icon {
                            margin: 0;
                            margin-left: 0;
                        }
                    }
                }

                & p {
                    opacity: 0;
                    visibility: hidden;
                    position: fixed;
                    left: 0;
                    white-space: nowrap;
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 16px;
                    border-radius: 8px;
                    background: #fff;
                    color: #000 !important;
                    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2) !important;
                    text-shadow: none !important;
                    transition: all .4s ease;
                    transition-delay: .1s;
                }

                &:hover>p {
                    opacity: 1;
                    visibility: visible;
                    left: 73px;
                }

                & .icon {
                    margin: 0;
                }
            }
        }
    }

    & .side_bar {
        width: $-side-bar-width;
        height: calc(100vh - 48px);
        padding: 0 16px;
        position: fixed;
        background: rgba(255, 255, 255, .75);
        backdrop-filter: blur(20px) saturate(280%);
        -webkit-backdrop-filter: blur(20px) saturate(280%);
        border-radius: 14px;
        box-shadow: 13px 13px 26px #d9d9d9,
            -13px -13px 26px #ffffff;
        display: flex;
        align-items: center;
        flex-direction: column;
        z-index: $-menu-z-index;
        transition: all .4s ease;

        @include mobile {
            display: none;
        }

        @include tablet {
            display: none;
        }

        & .menu_header {
            width: 100%;
            display: flex;
            align-items: center;
            height: 36px;

            & span {
                font-size: 13px;
                font-weight: 600;
                color: #414141;
                margin-left: 18px;
                opacity: .75;
            }
        }

        & .item {
            width: 100%;
            height: 36px;
            border-radius: 6px;
            margin: 10px 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            flex-direction: row;
            //transition: all .25s ease;
            text-decoration: none;

            &.avatar_container {
                height: 55px;
                margin-left: 0;

                & .avatar {
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    background: #414141;
                }

                & .user_name {
                    font-size: 13px;
                    margin-left: 24px;
                    color: #414141;
                    font-weight: 500;
                }

                & button {
                    width: auto;
                    display: inline-block;
                    cursor: pointer;

                    & .icon {
                        margin: 0;
                        margin-left: 12px;
                    }
                }

                &:active {
                    box-shadow: none;
                    transform: none;
                }
            }

            & .icon {
                font-size: 20px;
                color: #414141;
                margin: 0 20px;
            }

            & .item_name {
                font-size: 13px;
                color: #414141;
                font-weight: 500;
            }

            &.active {
                background-color: $-blue;

                & .icon {
                    color: #fff;
                    text-shadow: 0px 6px 6px rgba(0, 0, 0, 0.15);
                }

                & .item_name {
                    color: #fff;
                    text-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
                }

                &:active {
                    box-shadow: none;
                }
            }

            &:active {
                /*transform: scale(.9);
                box-shadow: inset 5px 5px 10px #ebebeb, 
                inset -5px -5px 10px #ffffff;*/
            }
        }
    }
}