@import "../common/variables";
@import "../lavenst.scss";

.view_header {
    font-size: 20px;
    font-weight: 500;
    color: $-blue;
    text-shadow: 0 3px 6px rgba(36, 132, 255,.36);

    @include mobile {
        text-align: center;
        margin-left:0!important;
    }

    @include tablet {
        text-align: center;
        margin-left:0!important;
    }
}