.tab_bar {
}

.cham_cong_table {
    & .tab_content_header_container {
        justify-content: flex-end!important;
        width: 1783px;

        @include mobile {
            max-width: unset;
            width: 1783px;
        }

        @include tablet {
            max-width: unset;
            width: 1783px;
        }

        & .tab_header_right_item_container {
            & .tab_header_item_title {
                margin: 0 16px;
                width: 15px;
                display: block;
                float: left;
            }
        }
    }

    & .tab_content {
        width: 1783px;
        & .tab_tick_container {
            & .tab_tick {
                margin-left: 133px!important;
                margin-top: -2px;

                @include mobile {
                    margin-left: 1170px!important;
                }

                @include tablet {
                    margin-left: 1170px!important;
                }

                & .column {
                    margin: 0 13.5px;

                    & .checkbox {
                        & input {

                        }

                        &:last-child {
                            margin-top: 12px;
                        }
                    }
                }
            }
        }
    }
}