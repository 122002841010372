@import "../../styles/common/variables";
@import "../../styles/lavenst.scss";

.douple_textbox {
    display: flex;
    width: 266px;

    & input {
        width: calc(50% - 8px);

        &:first-child {
            margin-right: 4px;
        }

        &:last-child {
            margin-left: 4px;
        }
    }
}

.bill_form_container,
.product_list_form_container {
    margin-left: 32px;
    display: flex;
    flex-direction: row;

    @include mobile {
        flex-direction: column;
        margin-left: 0 !important;

        & .column {
            & .box {
                align-items: center;

                & select,
                input {
                    width: 100%;
                }
            }
        }
    }

    @include tablet {
        flex-direction: column;
        margin-left: 0 !important;

        & .column {
            & .box {
                align-items: center;

                & select,
                input {
                    width: 100%;
                }
            }
        }
    }

    &.product_list_form_container {
        margin-left: 48px;

        & .column {
            margin-top: 48px;
        }
    }

    & .column {
        flex: 1;
        margin-top: 128px;

        & .box {
            display: flex;
            justify-content: center;
            flex-direction: column;

            & .title {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 4px;
                margin-top: 16px;
                font-size: 12.5px;
                font-weight: 600;
                color: #696969;
            }

            & .hPEdlB {
                width: 266px;

                & input {
                    width: 100%;
                    max-width: unset;
                }
            }
        }
    }
}

form {

    &.login {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        & input {
            margin-bottom: 16px;
        }

        & button {
            margin-top: 16px;
            width: 100%;
        }
    }
}