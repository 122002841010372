@import "../../styles/lavenst.scss";

.calendar {
    width: 100%;
    height: 100%;
    position: relative;

    & .calendar_header {
        width: 100%;
        padding: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        & .control_container {
            display: flex;

            & button {
                cursor: pointer;

                &:first-child {
                    margin-right: 24px;
                }

                &:last-child {
                    margin-left: 24px;
                }

                &:active {
                    box-shadow: none;
                }

                & .icon {
                    font-size: 16px;
                    color: #888;
                }
            }
        }

        & .date_columns {
            width: 100%;
            display: flex;

            & .column {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 32px;

                @include mobile {
                    padding: 0;

                    & span {
                        font-size: 10px!important;
                    }
                }

                & span {
                    font-size: 16px;
                    font-weight: 500;
                    color: #888;
                }
            }
        }
    }

    & .calendar_container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        
        & .calendar_block {
            flex: 1 0 14%; /* explanation below */
            min-height: 160px;
            border: 1px solid #eaeaea;
            position: relative;
            cursor: pointer;
            transition: all .15s ease;

            &.focus{
                box-shadow: inset 0 0 0 2px rgba(0, 123, 255, .5);
                border: 1px solid  rgba(0, 123, 255, .5);
                background: rgba(0, 123, 255, .15);
            }

            @include mobile {
                height: auto;
                width: 13.6%;
                flex: unset;

                & .events_container {
                    width: 100%!important;
                    left: 0!important;
                    bottom: 0!important;
                    height: auto!important;
                    padding-bottom: 8px; 

                    & .container {
                        height: auto!important;

                        & .event {
                            height: auto!important;

                            & .date {
                                display: none!important;
                            }

                            & p.content { 
                                margin: 4px!important;
                                font-size: 11px!important;
                            }

                            &.back {
                                height: 50%!important;
                                margin-top: 17px;
                            }
                        }
                    }
                }
            }

            & .date_container {
                position: absolute;
                top: 16px;
                right: 16px;
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6px;

                &.active {
                    background: #3880ff;
                    text-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);

                    & span {
                        color: #fff;
                    }
                }
                
                & span {
                    font-size: 16px;
                    padding: 0;
                    margin: 0;
                }
            }

            & .events_container {
                width: 100%;
                height: auto;
                margin-top: 48px;
                padding-bottom: 8px;
                display: flex;
                align-items: center;
                justify-content: center;

                & .container {
                    width: calc(100% - 16px);
                    height: 100%;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    flex-direction: column;

                    @include mobile {
                        width: 100%!important;
                    }

                    & .event {
                        width: 100%;
                        height: 22px;
                        border-radius: 8px;
                        box-shadow: 0 0 4px rgba(0,0,0,.2);
                        z-index: 9;
                        margin-top: 4px;
                        transition: all .2s ease;

                        @include mobile {
                            border-radius: 4px;
                        }

                        &:active {
                            transform: scale(.95);
                        }

                        & .date {
                            font-size: 12px;
                            margin: 12px 12px;
                            display: block;
                            color: #fff;
                            font-weight: 600;
                        }

                        //COLORS
                        &.blue {
                            background: #3880ff;
                        }
    
                        &.purple {
                            background: #5260ff;
                        }
    
                        &.orange {
                            background: orange;
                        }
    
                        &.yellow {
                            background: #ffc409;
                        }
    
                        &.red {
                            background: #eb445a;
                        }
    
                        &.gray {
                            background: #888;
                        }
    
                        &.black {
                            background: #414141;
                        }

                        &.null {
                            background: #888;
                        }

                        & p.content {
                            font-size: 12px;
                            margin: 4px 8px;
                            color: #fff;
                            white-space: wrap;
                            word-wrap: wrap;
                            text-align: left;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1; /* number of lines to show */
                            -webkit-box-orient: vertical;

                            @include mobile {
                                font-size: 6px!important;
                            }
                        }

                        &.back {
                            width: 90%;
                            height: 100%;
                            opacity: .5;
                            position: absolute;
                            top: 4px;
                            z-index: 0;
                        }
                    }
                }
            }
        }
    }
}

.event_card {
    width: calc(100% - 64px);
    margin: 16px;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 0 4px rgba(0, 0, 0, .2);
    z-index: 9;
    text-decoration: none;

    & .date {
        font-size: 12px!important;
        margin: 12px 12px!important;
        display: block!important;
        color: #fff!important;
        font-weight: 600!important;
        text-decoration: none!important;
    }

    &:last-child {
        margin-bottom: 48px;
    }

    //COLORS
    &.blue {
        background: #3880ff;
    }

    &.purple {
        background: #5260ff;
    }

    &.orange {
        background: orange;
    }

    &.yellow {
        background: #ffc409;
    }

    &.red {
        background: #eb445a;
    }

    &.gray {
        background: #888;
    }

    &.black {
        background: #414141;
    }

    &.null {
        background: #888;
    }

    & p.content {
        font-size: 12px;
        text-decoration: none;
        margin-left: 12px;
        margin-right: 12px;
        color: #fff;
        white-space: wrap;
        word-wrap: wrap;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        -webkit-box-orient: vertical;
    }
}

.calendar_action_buttons {
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 9999999;
    
    & .buttons_container {
        position: relative;

        & .button {
            width: 0;
            height: 0;
            background: #fff;
            box-shadow: 0 0 10px rgba(0,0,0,.4);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .25s ease;
            cursor: pointer;

            & .icon {
                font-size: 20px;
                color: #000;
                text-decoration: none;
            }
        }
    }

    &.active {
        & .button {
            width: 48px;
            height: 48px;
        }
    }
}