.btn {
    height: 32px;
    padding: 8px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 0 10px;
    background: $-white-2;
    color: $-dark-4;
    font-weight: 500;
    border-radius: 6px;
    outline: none;
    border: none;
    cursor: pointer;

    &.link_btn {
        font-size: 13px;
        text-decoration: none;
        height: auto;
    }

    & .icon {
        color: $-dark-4;
        margin-right: 6px;
    }

    &.blue {
        background: $-blue;
        color: #fff;

        &:active{
            transform: scale(.9);
            /*box-shadow: inset 5px 5px 10px $-blue-2, 
            inset -5px -5px 10px $-blue;*/
        }
    }
}