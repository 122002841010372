.google_calendar_frame {
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
}

.sc-ifAKCX.cXcRCo {
    right: 0;
    margin-top: 16px;
}

.box.calendar {
    & .sc-bxivhb.hPEdlB {
        & .sc-ifAKCX.cXcRCo {
            right: unset;
        }
    }
}