//COLORS
//#region
$-red: #f73558;
$-pink: #e94256;

$-blue: #2484FF;
$-blue-2: #1877F2;
$-blue-3:#77A7FF;
$-blue-4: #AAC9FF;
$-blue-5: #eaf3ff;

$-dark: #233745;
$-dark-2: #333;
$-dark-3: #414141;
$-dark-4: #888888;
$-dark-5:#959595;
$-black: #1d1d1f;

$-white-2: #F8F8F8;
$-white-3: #F9F9F9;
$-white-8: #bdbdbd;

$-gray: #a3a3a3;
$-gray-2: #ebedf0;
$-gray-3: #cfcfcf;
$-gray-4: #e3e3e5;
$-gray-5: #F5F5F5;

//SIZE
$-side-bar-width: 252px;

//Z-INDEX
$-menu-z-index: 9999;

//FONTS
$-default-font: "Google Sans";
//#endregion